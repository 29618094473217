import axios from "axios";
class TribeService {
  async getAllTopics() {
    return axios
      .get("https://inaarii.tribe.so/api/v1/topics")
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  }
}

export default new TribeService();
