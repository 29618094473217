import createStore from "../createStore";

const initialState = {
  flows: [],
  symptoms: []
};

const [{ setFlowList, setSymptomList }, reducer] = createStore("iNaari/flowSymptomList", {
  _initialState: initialState,

  setFlowList: (flows) => (state) => ({
    ...state,
    flows,
  }),
  setSymptomList: (symptoms) => (state) => ({
    ...state,
    symptoms,
  }),
});

export { setFlowList, setSymptomList };
export default reducer;
