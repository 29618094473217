import createStore from "../createStore";

const initialState = {
  topics: [],
};

const [{ setTribeTopics }, reducer] = createStore("iNaari/tribe", {
  _initialState: initialState,

  setTribeTopics: (topics) => (state) => ({
    ...state,
    topics,
  }),
});

export { setTribeTopics };
export default reducer;