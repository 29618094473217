import createStore from "../createStore";

const initialState = {
  banners: [],
  cycleAlignDescription: []
};

const [{ setHomePageBanner, setCycleAlignDescription }, reducer] = createStore("iNaari/home", {
  _initialState: initialState,

  setHomePageBanner: (banners) => (state) => ({
    ...state,
    banners,
  }),
  setCycleAlignDescription: (cycleAlignDescription) => state => ({
    ...state,
    cycleAlignDescription
  })
});

export { setHomePageBanner, setCycleAlignDescription };
export default reducer;
