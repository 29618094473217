import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isNil } from "lodash";
import { connect } from "react-redux";
import HeaderSubMenuComponent from "./HeaderSubMenuComponent";
import {loggedInUserSelector, themeColorSelector, unReadNotificationsCountSelector} from '../../../store/selectors';
import style from "./HeaderComponent.module.scss";
import MainMenuComponent from "../MainMenuComponent/MainMenuComponent";
import { getTitleFromURL } from "../../../utils";
import HeaderNotificationComponent from "./HeaderNotificationComponent";
import useApi from "../../../hooks/useApi";
import { setUnReadNotificationsCount } from "../../../store/appStore/auth";


function HeaderComponent({ routeMeta, themeColor, user, setUnReadNotificationsCount, unReadNotificationsCount}) {
  let history = useHistory();

  const [unReadNotificationsCountResult] = useApi(['user-notifications', 'count'], {
    params: { hasRead: false, 'user.id': user.id}
  });

  useEffect(() => {
    setUnReadNotificationsCount(unReadNotificationsCountResult);
  }, [unReadNotificationsCountResult]);

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showMainMenu, setShowMainMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const onSubMenuClick = useCallback(() => {
    setShowSubMenu(!showSubMenu);
  }, [showSubMenu]);

  const onMainMenuClick = useCallback(() => {
    setShowMainMenu(!showMainMenu);
  }, [showMainMenu]);

  const onNotificationClick = useCallback(() => {
    setShowNotification(!showNotification);
  }, [showNotification])

  return (
    <>
      <header className={`navbar navbar-expand-lg navbar-light fixed-top sticky-top ${!themeColor ? 'bg-white' : ''} header ${style.headerShadow}`} style={{backgroundColor: themeColor}}>
        {
          !isNil(routeMeta) && !isNil(routeMeta.heading) ? (
            <>
              <div className="navbar-brand mr-0 mr-md-2 d-flex align-items-center"><i
                    className="far fa-long-arrow-left pointer" onClick={() => history.goBack()} ></i> <span className="font-size-xsmall ml-2">{
                      !!routeMeta.heading ? routeMeta.heading : getTitleFromURL()
                    }</span></div>
            </>
          ) : (
            <div id="mainmenu" className={`navbar-brand mr-0 mr-md-2 ${style.pointer}`} onClick={onSubMenuClick}>
              <span className="navbar-toggler-icon"></span>
            </div>
          )
        }
        <ul className="navbar-nav flex-row ml-md-auto d-md-flex mr-0">
          {/* <li className="nav-item">
                    <div className="nav-link"><i className="fal fa-shopping-cart"></i></div>
                </li> */}
          <li className="nav-item">
            <div className={`nav-link position-relative ${style.pointer} ${style.notifacationBell}`} onClick={onNotificationClick}>
              <i className="far fa-bell"></i>
              {unReadNotificationsCount ? <span>{unReadNotificationsCount}</span> : ''}
            </div>
          </li>
          <li className="nav-item dropdown ellipsis-dropdownMenu">
            {/* <div
              className={`nav-link ${style.pointer}`}
              onClick={onSubMenuClick}
            >
              <i className="far fa-ellipsis-v"></i>
            </div> */}
            {showSubMenu && (
              <HeaderSubMenuComponent
                onSubMenuClick={onSubMenuClick}
                styles={style}
              />
            )}
          </li>
        </ul>
        {showSubMenu && (
          <div onClick={onSubMenuClick} className={style.overlay}></div>
        )}
      </header>
      {showMainMenu && <MainMenuComponent onMenuClose={onMainMenuClick}/>}
      {showNotification && <HeaderNotificationComponent user={user} onMenuClose={onNotificationClick} setUnReadNotificationsCount={setUnReadNotificationsCount} />}
    </>
  );
}

export default connect(state => ({
  themeColor: themeColorSelector(state),
  user: loggedInUserSelector(state),
  unReadNotificationsCount: unReadNotificationsCountSelector(state)
}), dispatch => ({
  setUnReadNotificationsCount: (unreadCount) => dispatch(setUnReadNotificationsCount(unreadCount))
}))(HeaderComponent);
