import createStore from "../createStore";

const initialState = {
  categories: [],
};

const [{ setBlogCategories }, reducer] = createStore("iNaari/blog", {
  _initialState: initialState,

  setBlogCategories: (categories) => (state) => ({
    ...state,
    categories,
  }),
});

export { setBlogCategories };
export default reducer;
