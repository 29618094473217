import createStore from "../createStore";

const initialState = {
  fastingList: [],
  fastingBanners: []
};

const [{ setFastingList, setFastingBannerInfo }, reducer] = createStore("iNaari/fasting", {
  _initialState: initialState,

  setFastingList: (fastingList) => (state) => ({
    ...state,
    fastingList,
  }),
  setFastingBannerInfo: fastingBanners => state => ({
    ...state, 
    fastingBanners
  })
});

export { setFastingList, setFastingBannerInfo };
export default reducer;
