import createStore from "../createStore";

const initialState = {
  faqList: [],
  faqCategories: [],
};

const [{ setFaq, setFaqCategories }, reducer] = createStore("iNaari/faq", {
  _initialState: initialState,

  setFaq: (faqList) => (state) => ({
    ...state,
    faqList,
  }),
  setFaqCategories: faqCategories => state => ({
    ...state, 
    faqCategories
  })
});

export { setFaq, setFaqCategories };
export default reducer;
