import { addHours } from 'date-fns';
import createStore from '../createStore';

const initialState = { 
    startTime: new Date(),
    endTime: addHours(new Date(), 12),
    isActiveFast: false,
    fastDetails: {},
    fastDuration: 0,
    fastCompletedDuration: 0,
    fastCompletedTime: null,
    activeFastConfig : {},
    fastHistoryConfig: {}
}

const [
    {setFastStartTime, setFastEndTime, setFastState, setFastConfig, setFastDuration, setFastCompletedDuration, setFastCompletedTime, setActiveFastConfig, setFastHistoryConfig, resetFastConfig},
    reducer,
  ] = createStore('iNaari/fastConfig', {
    _initialState: initialState,
    
    setFastStartTime: startTime => state => ({
      ...state,
      startTime,
    }),
    setFastEndTime: endTime => state => ({
        ...state,
        endTime,
    }),
    setFastState: isActiveFast => state => ({
        ...state,
        isActiveFast,
    }),
    setFastConfig: (fastDetails) => state => ({
        ...state,
        fastDetails
    }),
    setFastDuration: (fastDuration) => state => ({
        ...state,
        fastDuration
    }),
    setFastCompletedDuration: (fastCompletedDuration) => state => ({
        ...state,
        fastCompletedDuration
    }),
    setFastCompletedTime : (fastCompletedTime) => state => ({
        ...state,
        fastCompletedTime
    }),
    setActiveFastConfig : activeFastConfig => state => ({
        ...state,
        activeFastConfig
    }),
    setFastHistoryConfig : fastHistoryConfig => state => ({
        ...state,
        fastHistoryConfig
    }),
    resetFastConfig : () => () => initialState
});

export {
    setFastStartTime, setFastEndTime, setFastState, setFastConfig, setFastDuration, setFastCompletedDuration, setFastCompletedTime, setActiveFastConfig, setFastHistoryConfig, resetFastConfig
}
export default reducer;