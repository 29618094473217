import fastingImg from "../assets/icons/fasting.svg";
import cycleImg from "../assets/icons/cycle-align.svg";
import organicImg from "../assets/icons/organic.svg";
import mensurationImg from "../assets/icons/mensuration-tab.svg";
import follicularImg from "../assets/icons/follicular-tab.svg";
import ovulationImg from "../assets/icons/ovulation-tab.svg";
import lutealImg from "../assets/icons/luteal-tab.svg";
import forYouMenuIcon from "../assets/icons/for-you-menu-icon.svg";
import weightLossIcon from "../assets/footer/weighing-scale.svg";
import communityIcon from "../assets/footer/community.svg";
import fastingIcon from "../assets/footer/fasting.svg";
import blogIcon from "../assets/footer/blog.svg";
import bloodSugerSettle from '../assets/icons/body-status-tick.png';
import fatBurning from '../assets/icons/body-status-3.png';
import Ketosis from '../assets/icons/body-status-2.png';
import StemCells from '../assets/icons/body-status-1.png';
import bloodSugerDrop from '../assets/icons/body-status-min.png';
import bodyDottedStatus from '../assets/icons/body-dotted-status.png';
import bloodSugerRise from '../assets/icons/body-status-plus.png';

export const STORAGE_KEYS = Object.freeze({
  IS_LOGGED_IN: "isLoggedIn",
});

export const faqFilterType = [
  {
    name: "All",
    code: "ALL",
    key: "all",
    img: null,
    className: "card-light-gold",
    globalColor: "goldColor",
  },
  {
    name: "Fasting",
    code: "FASTING",
    key: "fasting",
    img: fastingImg,
    className: "card-cyan-dark",
    globalColor: "cyanColor",
  },
  {
    name: "Cycle Align",
    code: "CYCLEALIGN",
    key: "cycle-align",
    img: cycleImg,
    className: "card-green",
    globalColor: "greenColor",
  },
  {
    name: "Organic",
    code: "ORGANIC",
    key: "organic",
    img: organicImg,
    className: "card-light-gold",
    globalColor: "goldColor",
  },
  {
    name: "Weight Loss",
    code: "WEIGHTLOSS",
    key: "weightloss",
    img: null,
    className: "card-cyan-dark",
    globalColor: "cyanColor",
  },
  {
    name: "Keto",
    code: "KETO",
    key: "keto",
    img: null,
    className: "card-green",
    globalColor: "greenColor",
  },
  {
    name: "Hormone Diet",
    code: "HORMONEDIET",
    key: "hormonediet",
    img: null,
    className: "card-light-gold",
    globalColor: "goldColor",
  },
];

export const faqs = [
  {
    question: "What is Ketobiotic Diet?",
    answer:
      "Your female sex hormone, primarily estrogen and progesterone fluctuate through the month. Where you are on your monthly cycle affects your mood, appetite, libido, sleep and productivity. When you eat, fast and exercise as per your menstrual cycle, you balance your hormones thereby gaining optimal energy and overall health.",
  },
  {
    question: "What is Ketobiotic Diet?",
    answer:
      "Your female sex hormone, primarily estrogen and progesterone fluctuate through the month. Where you are on your monthly cycle affects your mood, appetite, libido, sleep and productivity. When you eat, fast and exercise as per your menstrual cycle, you balance your hormones thereby gaining optimal energy and overall health.",
  },
  {
    question: "What is Ketobiotic Diet?",
    answer:
      "Your female sex hormone, primarily estrogen and progesterone fluctuate through the month. Where you are on your monthly cycle affects your mood, appetite, libido, sleep and productivity. When you eat, fast and exercise as per your menstrual cycle, you balance your hormones thereby gaining optimal energy and overall health.",
  },
];

const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const dateConfig = {
  year: {
    format: "YYYY",
    caption: "Year",
    step: 1,
  },
  month: {
    format: (value) => monthMap[value.getMonth() + 1],
    caption: "Month",
    step: 1,
  },
  date: {
    format: "DD",
    caption: "Day",
    step: 1,
  },
};

export const numberConfig = {
  minute: {
    format: "mm",
    caption: "Min",
    step: 1,
  },
};

export const cycleHistoryVariationChart = [
  { name: "Jan", variation: 3 },
  { name: "Feb", variation: 4 },
  { name: "Mar", variation: 3 },
  { name: "Apr", variation: 5 },
  { name: "May", variation: 3 },
  { name: "Jun", variation: 4 },
  { name: "Jul", variation: 5 },
  { name: "Aug", variation: 3 },
  { name: "Sep", variation: 4 },
  { name: "Oct", variation: 5 },
  { name: "Nov", variation: 3 },
  { name: "Dec", variation: 2 },
];

export const cycleHistorySummaryData = [
  {
    dateRange: "01 Jun 2020 to 07 Jul 2020",
    totalDays: 37,
    symptomCount: 1,
    summaryDayCount: 10,
    symptomDetails: [
      { name: "Diarrhea", img: "" },
      { name: "Disturbed Sleep", img: "" },
      { name: "Fatigue", img: "" },
      { name: "Headache", img: "" },
    ],
  },
];

export const foodToEatData = [
  {
    id: 1,
    question: "Foods Rich in Iron",
    answer:
      "During times of heavy flow it can be helpful to supplement with iron to prevent anaemia",
  },
  {
    id: 2,
    question: "Foods Rich in Vitamin C",
    answer:
      "During times of heavy flow it can be helpful to supplement with iron to prevent anaemia",
  },
  {
    id: 3,
    question: "Foods Rich in Vitamin B12",
    answer:
      "During times of heavy flow it can be helpful to supplement with iron to prevent anaemia",
  },
  {
    id: 4,
    question: "Foods Rich in Omega 3 Oils",
    answer:
      "During times of heavy flow it can be helpful to supplement with iron to prevent anaemia",
  },
  {
    id: 5,
    question: "Foods Rich in Zinc",
    answer:
      "During times of heavy flow it can be helpful to supplement with iron to prevent anaemia",
  },
  {
    id: 6,
    question: "High Fiber, Low GI Carbo-hydrates Food",
    answer:
      "During times of heavy flow it can be helpful to supplement with iron to prevent anaemia",
  },
  {
    id: 7,
    question: "Foods that balance hormone",
    answer:
      "During times of heavy flow it can be helpful to supplement with iron to prevent anaemia",
  },
];

export const cyclePhases = [
  { name: "Menstruation", image: mensurationImg },
  { name: "Follicular", image: follicularImg },
  { name: "Ovulation", image: ovulationImg },
  { name: "Luteal", image: lutealImg },
];

export const dietPlans = [
  {
    category: "Seeds",
    categoryDesc: "(1 tbsp each)",
    className: "bg-light-cyan",
    items: ["Flaxseeds", "Pumpkin Seeds"],
  },
  {
    category: "Nuts",
    categoryDesc: "(1 tbsp each)",
    className: "bg-light-green",
    items: ["Almonds", "Cashew"],
  },
  {
    category: "Fermented Foods",
    categoryDesc: "(any one)",
    className: "bg-light-gold",
    items: ["Khimchi", "Sauerkraut", "Kefir Water", "Kombucha"],
  },
  {
    category: "Polyphenol Foods",
    categoryDesc: "(any one)",
    className: "bg-light-cyan",
    items: ["Clove", "Chocolate", "Dsogudsug", "KSHGksdh"],
  },
];

export const appMainMenu = [
  { name: "For You", url: "home", menuIcon: "", image: forYouMenuIcon },
  {
    name: "Weight Loss",
    url: "weight-loss",
    menuIcon: "",
    image: weightLossIcon,
  },
  { name: "Community", url: "community", menuIcon: "", image: communityIcon },
  { name: "Fasting", url: "start-fasting", menuIcon: "", image: fastingIcon },
  { name: "Blog", url: "blog", menuIcon: "", image: blogIcon },
  // {
  //   name: "Health marketplace",
  //   url: "markteplace",
  //   menuIcon: "fa-shopping-bag",
  //   image: "",
  // },
  { name: "FAQs", url: "faq", menuIcon: "fa-question-square", image: "" },
];

export const cycleChartData = {
  MENSURATION_DAYS: {
    name: "Mensuration Phase",
    subTitle: "Inner Cycle: Winter",
    phaseCode: "MENSURATION",
    value: 100,
    color: "#f78579",
  },
  LUTEAL_DAYS: {
    name: "Luteal Phase",
    subTitle: "Summer Cycle",
    phaseCode: "LUTEAL",
    value: 200,
    color: "#86e3ce",
  },
  OVULATION_DAYS: {
    name: "Ovulation Phase",
    phaseCode: "OVULATION",
    subTitle: "Fall cycle",
    value: 500,
    color: "#d0e6a5",
  },
  FOLLICULAR_DAYS: {
    name: "Follicular Phase",
    subTitle: "Autum cycle",
    phaseCode: "FOLLICULAR",
    value: 200,
    color: "#ffdd95",
  },
};

export const cycleType = {
  MENSURATION_DAYS: "MENSURATION",
  LUTEAL_DAYS: "LUTEAL",
  OVULATION_DAYS: "OVULATION",
  FOLLICULAR_DAYS: "FOLLICULAR",
};

export const phaseType = {
  REGULAR : 'REGULAR',
  IRREGULAR: 'IRREGULAR'
}

export const dietType = {
  KETO : 'KETO',
  HORMONE: 'HORMONE'
}

export const premiumPacks = [
  {
    id: 1,
    planAmout: 5000,
    type: "yearly",
    amountDesc: "Only ₹ 416 / month",
    discount: "SAVE 50%",
  },
  { id: 2, planAmout: 780, type: "monthly", amountDesc: "", discount: "" },
];

export const settingReminders = [
  {
    id: 1,
    type: "Chat",
    items: [
      { id: 1, name: "Chat Push Notifications", desc: "", isActive: true },
    ],
  },
  {
    id: 2,
    type: "Cycle",
    items: [
      {
        id: 1,
        name: "Period in couple of days",
        desc: "10:00 AM",
        isActive: false,
      },
      { id: 2, name: "Period Start", desc: "10:00 AM", isActive: false },
      { id: 3, name: "Period End", desc: "10:00 AM", isActive: false },
    ],
  },
  {
    id: 3,
    type: "Diet Change",
    items: [
      {
        id: 1,
        name: "Switch Diet Type in one day",
        desc: "10:00 AM",
        isActive: false,
      },
      {
        id: 2,
        name: "New Diet begins today",
        desc: "08:00 AM",
        isActive: true,
      },
    ],
  },
  {
    id: 4,
    type: "Fasting",
    items: [
      {
        id: 1,
        name: "Start fasting",
        desc: "Deactivated during a fast",
        isActive: false,
      },
      {
        id: 2,
        name: "Halfway through",
        desc: "Only 4 hour or longer fasts",
        isActive: true,
      },
      {
        id: 3,
        name: "Last hour",
        desc: "Only 4 hour or longer fasts",
        isActive: false,
      },
    ],
  },
];

export const fastPhaseType = [
  {
    key: "IF (Intermittent Fasting)",
    backColor: "#F1F7E6",
    color: "#354e01",
    fastType: [
      { type: "Beginner", phases: ["13:11", "14:10", "15:9"] },
      { type: "Experienced", phases: ["16:8", "17:7", "18:6"] },
    ],
  },
  {
    key: "OMAD (One Meal a Day)",
    backColor: "#FFDD95",
    color: "#4a3405",
    fastType: [{ type: "Experienced", phases: ["23:01"] }],
  },
  {
    key: "BF Block Fast",
    backColor: "#43A58F",
    color: "#000e00",
    fastType: [{ type: "Experienced", phases: ["48 hrs", "72 hrs"] }],
  },
  {
    key: "Autophagy Fasting",
    backColor: "#D8A63C",
    color: "#402c01",
    fastType: [{ type: "Experienced", phases: ["23:01", "23:01"] }],
  },
  {
    key: "Dry Fast",
    backColor: "#D8A63C",
    color: "#402c01",
    fastType: [{ type: "Experienced", phases: ["5 Days"] }],
  },
  {
    key: "Fast Mimicking",
    backColor: "#FFDCD6",
    color: "#581d13",
    fastType: [{ type: "Experienced", phases: ["5 Days"] }],
  },
];

export const fastMonthlyChartData = [
  { name: "Aug 5", variation: 3, color: "#FFDD95" },
  { name: "Aug 12", variation: 4, color: "#86E3CE" },
  { name: "Aug 19", variation: 3, color: "#D0E6A5" },
  { name: "Aug 26", variation: 5, color: "#FB877A" },
  { name: "Sep 2", variation: 3, color: "#dddddd" },
];

export const fastDateTimeConfig = {
  date: {
    format: "DD",
    caption: "Day",
    step: 1,
  },
  month: {
    format: (value) => monthMap[value.getMonth() + 1],
    caption: "Mon",
    step: 1,
  },
  hour: {
    format: "hh",
    caption: "Hour",
    step: 1,
  },
  minute: {
    format: "mm",
    caption: "Min",
    step: 1,
  },
};

export const questionaire = [
  {
    id: 1,
    question: "How often do you drink alcohol?",
    questionsOptions: [
      { option: "3 or more times a week", result: 8 },
      { option: "1-2 times a week", result: 11 },
      { option: "Less than 6 times a month", result: 20 },
      { option: "Less than 3 times a month", result: 17 },
      { option: "I do not drink alcohol", result: 47 },
    ],
  },
];

export const blogStaicCategory = {
  whatsHotkey: 'Whats Hot',
  topArticlekey: 'Top Article',
  all: 'All',
}

export const reminderPrefKeys = [
  {keyName: 'Chat', keyTitle: 'Chat'},
  {keyName: 'Cycle', keyTitle: 'Cycle'}, 
  {keyName: 'cyclePhases', keyTitle: 'Cycle Phase'},
  {keyName: 'fasting', keyTitle: 'Fasting'}
] 

export const whatsHappingIndicators = [
  {fastHours: 0, title: 'Blood Sugar Level Rises', className: 'body-status-min-icon', image: bloodSugerRise},
  {fastHours: 3, title: 'Blood Sugar Level Drops', className: 'body-dotted-status-icon', image: bloodSugerDrop},
  {fastHours: 9, title: 'Blood Sugar Level Settles Down', className: 'body-status-plus-icon', image: bloodSugerSettle},
  {fastHours: 11, title: 'Fat Burn On', className: 'body-status-tick-icon', image: fatBurning},
  {fastHours: 15, title: 'You are in Ketosis', className: 'body-status3-icon', image: Ketosis},
  {fastHours: 17, title: 'Authophagy', className: 'body-status2-icon', image: bodyDottedStatus},
  {fastHours: 72, title: 'Stem Cells Generated', className: 'body-status1-icon', image: StemCells}, 
]