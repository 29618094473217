import React, { useEffect, Suspense } from "react";
import { Provider } from "react-redux";
import { isNil } from "lodash";
import { store } from "./app/store/store";
import { setAppInit } from "./app/store/appStore/appInit";
import { BrowserRouter, Switch } from "react-router-dom";
import { GuardProvider } from "react-router-guards";
import { getIsLoggedIn } from "./app/utils";
import getRoutes from "./app/router/routes";
import RouteLayer from "./app/components/core/RouteLayer";
import ErrorPage from "./app/components/pages/ErrorPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./app/assets/scss/bootstrap.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { dobSelector } from "./app/store/selectors";
import LoaderSpinner from "./app/components/core/LoaderSpinner";
import AppToastNotification from "./app/components/core/AppToastNotification";
import CacheBuster from './CacheBuster';
import BootAppComponent from "./app/components/app/BootAppComponent";
import FirebaseService from "./app/services/FirebaseService";
import ErrorBoundary from "./app/components/app/ErrorComponent/ErrorBoundary";

function App() {
  const loader = document.getElementById('splashLoader');

  useEffect(() => {
    store.dispatch(setAppInit(true));
    loader.setAttribute('style', 'display:none');
    FirebaseService.initFirebase();
  }, [loader]);

  const requireLogin = (to, from, next) => {
    if (to.meta.redirect) {
      window.location = to.meta?.url;
      return;
    }

    if (to.meta.isAuth) {
      if (getIsLoggedIn()) {
        isNil(dobSelector(store.getState()))
          ? next.redirect("/cycle-info")
          : next();
      }
      next.redirect("/login");
    } else {
      if (getIsLoggedIn()) {
        next.redirect("/home");
      } else {
        next();
      }
    }
  };

  const routes = React.useMemo(() => getRoutes(), []);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<LoaderSpinner />}>
            <ErrorBoundary>
              <GuardProvider
                guards={[requireLogin]}
                loading={LoaderSpinner}
                error={ErrorPage}
              >
                <Switch>
                  {routes.map(({ component, exact, path, meta }, i) => {
                    return (
                      <RouteLayer
                        key={i}
                        exact={exact}
                        component={component}
                        path={path}
                        meta={meta}
                      />
                    );
                  })}
                </Switch>
              </GuardProvider>
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
      </Provider>
      <AppToastNotification />
    </>
  );
}

export default App;
