import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { oneSignal } from './app/config';
import * as serviceWorker from './serviceWorker';

// const OneSignal = window.OneSignal;
// OneSignal.push(function () {
//   OneSignal.init({
//     appId: oneSignal.appId,
//     safari_web_id:
//       oneSignal.safariWebId,
//     notifyButton: {
//       enable: true,
//     },
//     allowLocalhostAsSecureOrigin: true
//   });
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
