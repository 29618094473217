const env = process.env;

const config = {
  apiBaseUrl: env.REACT_APP_API_BASE_URL,
  websiteUrl: env.REACT_APP_WEBSITE_URL,
  socialLoginApiUrl: env.REACT_APP_API_SOCIAL_LOGIN,
  firebaseConfig: {
    apiKey: "AIzaSyC5Zv9AMF_C8q4UBbaR6cXrRjFkoG9ZxMo",
    authDomain: "inaari-15d1c.firebaseapp.com",
    projectId: "inaari-15d1c",
    storageBucket: "inaari-15d1c.appspot.com",
    messagingSenderId: "497123091432",
    appId: "1:497123091432:web:0cddcf4fffbd505e823a5d"
  },
  oneSignal: {
    appId: env.REACT_APP_ONESIGNAL_APPID,
    safariWebId: env.REACT_APP_ONESIGNAL_WEBID
  },
};


export default config;
export const socialLoginApiUrls = config.socialLoginApiUrls;
export const firebaseConfig = config.firebaseConfig;
export const { oneSignal } = config;
