import { get } from "lodash";
import { toast } from "react-toastify";
import qs from "qs";
import { setAuth, setTribeToken, setUnReadNotificationsCount } from "../store/appStore/auth";
import { dispatch, getState, store } from "../store/store";
import { fcmTokenSelector, getCurrentPhaseModel } from "../store/selectors";
import apiService from "./ApiService";
import StaticPagesService from "./StaticPagesService";
import FastingService from "./FastingService";
import ReminderPreferenceService from './ReminderPreferenceService';
import { setBlogCategories } from "../store/appStore/blog";
import HomeService from "./HomeService";
import { setFaqCategories } from "../store/appStore/faq";
import config from '../config';
import { setShowTour } from "../store/appStore/showTour";
class AuthService {
  constructor() {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    store.dispatch(setAuth({ token, user }));

    if (token) {
      StaticPagesService.getAppVersion();
      FastingService.getActiveFastConfig();
      ReminderPreferenceService.getReminderPreference();
      this.fetchTribeAuthToken();
      this.getBlogCategories();
      HomeService.onHomePageReady();
    }
  }

  getPhase() {
    return getCurrentPhaseModel(getState())
      ? getCurrentPhaseModel(getState()).title
      : "Phase";
  }

  async loginWithEmailAndPassword({ email, password }) {
    try {
      const response = await apiService.post("/auth/local", {
        identifier: email,
        password,
      });

      return this.afterLogin(response);
    } catch (error) {
      const errorMessage = get(error, "message.0.messages.0.message");
      if (errorMessage === "Please provide your username or your e-mail.") {
        toast.error("Email not registered!");
      }

      if (errorMessage === "Identifier or password invalid.") {
        toast.error("Email or password invalid!");
      }
    }
  }

  async register(formValues) {
    try {
      const response = await apiService.post("/auth/local/register", {
        ...formValues,
        username: formValues.email,
      });

      return this.afterLogin(response);
    } catch (error) {
      console.log(error);
      const formValidationError = get(error, "data.0.messages.0.message");
      if (formValidationError) {
        toast.error(formValidationError);
      }

      throw error;
    }
  }

  async deleteUser(userId) {
    try {
      await apiService.delete(`/users/${userId}`)
    } catch (e) {
      toast.error(e);
    }
  }

  async afterLogin(response) {
    localStorage.setItem("token", response.jwt);
    localStorage.setItem("user", JSON.stringify(response.user));
    store.dispatch(setAuth({ token: response.jwt, user: response.user }));
    const isTribeLogin = localStorage.getItem("isTribeLogin");
    const redirect = localStorage.getItem("redirect");
    // const websiteLoginUrl = `${config.websiteUrl}/sso/login?token=${response.jwt}`;
    // window.open(websiteLoginUrl, '_blank');

    if (isTribeLogin && isTribeLogin === 'true') {
      // Redirect to tribe page
      localStorage.removeItem("isTribeLogin");
      const tribeToken = await this.fetchTribeAuthToken();
      const redirectUrl = `https://inaarii.tribe.so/auth/sso?ssoToken=${tribeToken}`;
      window.location.replace(redirectUrl);
    }

    if (redirect) {
      const url = new URL(redirect);

      const host = url.origin;
      const pathname = url.pathname;
      const search = qs.parse(url.search, { ignoreQueryPrefix: true });
      const queryString = qs.stringify({ ...search, token: response.jwt});

      const redirectUrl = `${host}${pathname}?${queryString}`;
      localStorage.removeItem("redirect");
      window.location.replace(redirectUrl);
    }
    Promise.all([
      this.fetchTribeAuthToken(),
      this.setFCMTokenToServer(),
      this.setFAQCategory()
    ])
    HomeService.onHomePageReady();
    return response;
  }

  async setFCMTokenToServer() {
    const fcmDeviceToken = fcmTokenSelector(getState())
    const loggedInUser = this.getLoggedInUser();
    await apiService.put(`/users/${loggedInUser.id}`, {
      fcmDeviceToken
    });
  }

  async fetchTribeAuthToken() {
    const url = "/tribe/get-jwt-token";
    const token = await apiService.get(url);
    store.dispatch(setTribeToken(token));
    return token;
  }

  async socialLogin({ provider, accessToken }) {
    const response = await apiService.get(
      `/auth/${provider}/callback?${qs.stringify({
        access_token: accessToken,
      })}`
    );

    return this.afterLogin(response);
  }

  async forgotPassword(data) {
    await apiService.post("/auth/forgot-password", data);
    toast.success("Reset password instruction sent on your email.");
  }

  async resetPassword(data) {
    const response = await apiService.post("/auth/reset-password", data);
    return this.afterLogin(response);
  }

  getLoggedInUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  getLoggedInUserToken() {
    return localStorage.getItem("token");
  }

  async createFirstCycleInfo(data) {
    const loggedInUser = this.getLoggedInUser();
    const responses = await Promise.all([
      apiService.put(`/users/${loggedInUser.id}`, {
        dob: data.dob,
        periodType: data.periodType,
      }),
      apiService.post("/period-cycles/create-first-period", {
        lastPeriodStartDate: data.lastPeriodStartDate,
        periodLength: data.periodLength,
        averageDurationOfCycle: data.averageDurationOfCycle,
        user: loggedInUser.id,
      }),
    ]);
    await this.afterLogin({
      jwt: this.getLoggedInUserToken(),
      user: responses[0],
    });
    store.dispatch(setShowTour(true)); // showing tour user who comes first time on the platform
    return responses;
  }
  async getBlogCategories() {
    const blogCategories = await apiService.get('/tags');
    if (blogCategories) {
      const categories = blogCategories.map(item => item.tag);
      dispatch(setBlogCategories(categories));
    }
  }

  async setFAQCategory () {
    const faqCategories = await apiService.get('/app-faq-categories');
    if (faqCategories.length) {
      dispatch(setFaqCategories(faqCategories))
    }
  }

  async fetchNoticationCount() {
    const loggedInUser = this.getLoggedInUser();
    if (loggedInUser && loggedInUser.id) {
      const count = await apiService.get('/user-notifications/count', {
        params: {
          'user.id': loggedInUser.id,
          'hasRead': false
        }
      });

      store.dispatch(setUnReadNotificationsCount(count));
    }

  }
}

export default new AuthService();
