import React, {useEffect} from 'react'
import { useParams } from "react-router-dom"
import config from '../../config';
import useQuery from '../../hooks/useQuery';

export default function SocialLoginPage({}) {

  const {provider} = useParams();
  const query = useQuery();
  const redirect = query.get("redirect");

  useEffect(() => {
    if (redirect && provider) {
      localStorage.setItem("redirect", redirect);
      const googleLoginUrl = `${config.apiBaseUrl}${config.socialLoginApiUrl}/${provider}`;
      window.location.replace(googleLoginUrl);
    }
  }, [provider, redirect]);

  return (
    <div>
      Loging In...
    </div>
  )
}
