import { useState, useEffect, useRef } from "react";
import ApiService from "../services/ApiService";

export default function useApi(urlSegments = [], configs = {}) {
  const [apiData, setApiData] = useState();
  const unmounted = useRef(false);

  useEffect(() => {
    ApiService.get(urlSegments.join("/"), configs).then(setApiData);
    // eslint-disable-next-line
    return () => { unmounted.current = true }
  }, [...urlSegments]);

  return [apiData, setApiData];
}
