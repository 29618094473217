// import { store } from "../store/store";
import ApiService from "./ApiService";
import { dispatch, store } from "../store/store";
import { setCycleAlignDescription, setHomePageBanner } from "../store/appStore/home";
import TribeService from "./TribeService";
import PeriodService from "./PeriodService";
import { setTribeTopics } from "../store/appStore/tribe";
import { setFaqCategories } from "../store/appStore/faq";
import { setFastingBannerInfo } from "../store/appStore/fasting";
class HomeService {
  async onHomePageReady() {
    try {
      Promise.all([
        this.getHomePageBanners(),
        PeriodService.getPeriodDetail(),
        this.getCycleAlignDescription(),
        this.setFAQCategories(),
        this.setFastingBanner()
      ]);
      const topics = await TribeService.getAllTopics();
      dispatch(setTribeTopics(topics));
    } catch (e) {
      console.log(e)
    }
  }
  
  async getHomePageBanners() {
    return ApiService.get("/home-page-banners").then(
      (banners) => {
        store.dispatch(setHomePageBanner(banners));
      }
    );
  }

  async getCycleAlignDescription() {
    return ApiService.get("/cycle-align-descriptions").then(
      (description) => {
        store.dispatch(setCycleAlignDescription(description));
      }
    );
  }

  async setFAQCategories() {
    return ApiService.get('/app-faq-categories').then((categories) => {
      store.dispatch(setFaqCategories(categories))
    })
  }

  async setFastingBanner() {
    return ApiService.get('/page-fastings').then((categories) => {
      store.dispatch(setFastingBannerInfo(categories))
    })
  }

}

export default new HomeService();
